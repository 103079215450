import React from "react";
import "../css/style.css";
import { Link } from "react-router-dom";
import avatar1 from "../assets/avatar4.jpeg";
function QAOrganizer() {
  return (
    <div className="stylecontainer">
      <div className="stylesidebar">
        <link
          rel="stylesheet"
          href="https://use.fontawesome.com/releases/v5.15.4/css/all.css"
          integrity="sha384-DyZ88mC6Up2uqS4h/KRgHuoeGwBcD4Ng9SiP4dIRy0EXTlnuz47vAwmeGwVChigm"
          crossOrigin="anonymous"
        />
        <ul>
          <li>
            <Link to="/home">
              <i className="fas fa-book"></i>
              <div className="styletitle">SkillBridge</div>
            </Link>
          </li>
          <li>
            <a href="#">
              <i className="fas fa-th-large"></i>
              <div className="styletitle">QA Dashboard</div>
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fas fa-tag"></i>
              <div className="styletitle">Manage Profiles</div>
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fas fa-tag"></i>
              <div className="styletitle">Trobleshoot Issues</div>
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fas fa-tag"></i>
              <div className="styletitle">Grant Permissions</div>
            </a>
          </li>
          <li>
            <Link to="/edit">
              <i className="fas fa-user"></i>
              <div className="styletitle">Edit Profile</div>
            </Link>
          </li>
          <li>
            {/* <a href="login.html">
              <i className="fas fa-user"></i>
              <div className="styletitle">Logout</div>
            </a> */}
            <Link to="/login">
              <i className="fas fa-user"></i>
              <div className="styletitle">Logout</div>
            </Link>
          </li>
        </ul>

        <div className="stylechatBody">
          <div className="stylechat">
            <div className="styleheader">
              <h3>
                <i className="fas fa-comments"></i> WebChat
              </h3>
            </div>
            <div className="StyleChatbody1">
              <p className="stylemessage">Hello</p>
              <p className="stylemessage user_message">Hi</p>
            </div>
            <div className="stylefooter">
              <form>
                <input type="text" name="" />
                <button>Send</button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="stylemain">
        <div className="styletop-bar">
          <div className="stylesearch">
            <input type="text" name="search" placeholder="Search here.." />
            <label htmlFor="search">
              <i className="fas fa-search"></i>
            </label>
          </div>
          <i className="fas fa-bell"></i>
          <div className="styleuser">
            <img src={avatar1} alt="skillbridge" />
          </div>
        </div>
        <div className="stylecards">
          <div className="stylecard">
            <div className="stylecard-content">
              <div className="stylenumber">25</div>
              <div className="stylecard-name">QA Policies</div>
            </div>
            <div className="styleicon-box">
              <i className="fas fa-tag"></i>
            </div>
          </div>
          <div className="stylecard">
            <div className="stylecard-content">
              <div className="stylenumber">23</div>
              <div className="stylecard-name">QA Processes</div>
            </div>
            <div className="styleicon-box">
              <i className="fas fa-tag"></i>
            </div>
          </div>
          <div className="stylecard">
            <div className="stylecard-content">
              <div className="stylenumber">5</div>
              <div className="stylecard-name">New Recommendations</div>
            </div>
            <div className="icon-box">
              <i className="fas fa-tag"></i>
            </div>
          </div>
          <div className="stylecard">
            <div className="stylecard-content">
              <div className="stylenumber">17</div>
              <div className="stylecard-name">Total Recommendations</div>
            </div>
            <div className="icon-box">
              <i className="fas fa-tag"></i>
            </div>
          </div>
        </div>
        <div className="styletables">
          <div className="StyleAllRoles">
            <div className="styleheading">
              <h2>Policies</h2>
              <a href="#" className="stylebtn">
                View All
              </a>
            </div>
            <table className="styleroles">
              <thead>
                <tr>
                  <th>Policy Name</th>
                  <th>Policy Number</th>
                  <th>Policy Description</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Policy 1</td>
                  <td>#1235</td>
                  <td>Policy Description 1</td>
                  <td>
                    <i className="fas fa-eye"></i>&nbsp;
                    <i className="fas fa-edit"></i>&nbsp;
                    <i className="fas fa-trash"></i>&nbsp;
                  </td>
                </tr>
                <tr>
                  <td>Policy 1</td>
                  <td>#1235</td>
                  <td>Policy Description 1</td>
                  <td>
                    <i className="fas fa-eye"></i>&nbsp;
                    <i className="fas fa-edit"></i>&nbsp;
                    <i className="fas fa-trash"></i>&nbsp;
                  </td>
                </tr>
                <tr>
                  <td>Policy 1</td>
                  <td>#1235</td>
                  <td>Policy Description 1</td>
                  <td>
                    <i className="fas fa-eye"></i>&nbsp;
                    <i className="fas fa-edit"></i>&nbsp;
                    <i className="fas fa-trash"></i>&nbsp;
                  </td>
                </tr>
                <tr>
                  <td>Policy 1</td>
                  <td>#1235</td>
                  <td>Policy Description 1</td>
                  <td>
                    <i className="fas fa-eye"></i>&nbsp;
                    <i className="fas fa-edit"></i>&nbsp;
                    <i className="fas fa-trash"></i>&nbsp;
                  </td>
                </tr>
                <tr>
                  <td>Policy 1</td>
                  <td>#1235</td>
                  <td>Policy Description 1</td>
                  <td>
                    <i className="fas fa-eye"></i>&nbsp;
                    <i className="fas fa-edit"></i>&nbsp;
                    <i className="fas fa-trash"></i>&nbsp;
                  </td>
                </tr>
                <tr>
                  <td>Policy 1</td>
                  <td>#1235</td>
                  <td>Policy Description 1</td>
                  <td>
                    <i className="fas fa-eye"></i>&nbsp;
                    <i className="fas fa-edit"></i>&nbsp;
                    <i className="fas fa-trash"></i>&nbsp;
                  </td>
                </tr>
                {/* Add more rows here */}
              </tbody>
            </table>
          </div>
          <div className="styleAllcourses">
            <div className="styleheading">
              <h2>Track Student Progress</h2>
              <a href="#" className="stylebtn">
                View All
              </a>
            </div>
            <table className="courses">
              <thead>
                <tr>
                  <th>Process Name</th>
                  <th>Process Number</th>
                  <th>Process Description</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Process 1</td>
                  <td>#1235</td>
                  <td>Process Description 1</td>
                  <td>
                    <i className="fas fa-eye"></i>&nbsp;
                    <i className="fas fa-edit"></i>&nbsp;
                    <i className="fas fa-trash"></i>&nbsp;
                  </td>
                </tr>
                <tr>
                  <td>Process 1</td>
                  <td>#1235</td>
                  <td>Process Description 1</td>
                  <td>
                    <i className="fas fa-eye"></i>&nbsp;
                    <i className="fas fa-edit"></i>&nbsp;
                    <i className="fas fa-trash"></i>&nbsp;
                  </td>
                </tr>
                <tr>
                  <td>Process 1</td>
                  <td>#1235</td>
                  <td>Process Description 1</td>
                  <td>
                    <i className="fas fa-eye"></i>&nbsp;
                    <i className="fas fa-edit"></i>&nbsp;
                    <i className="fas fa-trash"></i>&nbsp;
                  </td>
                </tr>
                <tr>
                  <td>Process 1</td>
                  <td>#1235</td>
                  <td>Process Description 1</td>
                  <td>
                    <i className="fas fa-eye"></i>&nbsp;
                    <i className="fas fa-edit"></i>&nbsp;
                    <i className="fas fa-trash"></i>&nbsp;
                  </td>
                </tr>
                <tr>
                  <td>Process 1</td>
                  <td>#1235</td>
                  <td>Process Description 1</td>
                  <td>
                    <i className="fas fa-eye"></i>&nbsp;
                    <i className="fas fa-edit"></i>&nbsp;
                    <i className="fas fa-trash"></i>&nbsp;
                  </td>
                </tr>
                <tr>
                  <td>Process 1</td>
                  <td>#1235</td>
                  <td>Process Description 1</td>
                  <td>
                    <i className="fas fa-eye"></i>&nbsp;
                    <i className="fas fa-edit"></i>&nbsp;
                    <i className="fas fa-trash"></i>&nbsp;
                  </td>
                </tr>
                {/* Add more rows here */}
              </tbody>
            </table>
          </div>
        </div>
        <div className="stylefeedback">
          <div className="stylefeedback1">
            <h1>Email New Recommendations</h1>
            <form>
              <input type="text" placeholder="Subject" />
              <input type="email" placeholder="Email ID...." />
              <textarea placeholder="Email New Recommendations..."></textarea>
              <button type="submit">Send Recommendation</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QAOrganizer;
