import React, { useState } from "react";
import "../css/signup.css";
import avatar from "../assets/logo.png";
import { Link } from "react-router-dom";

function Signup() {
  const [selectedRole, setSelectedRole] = useState("");
  const [redirectTo, setRedirectTo] = useState(null);

  const handleRoleChange = (e) => {
    const role = e.target.value;
    setSelectedRole(role);
  };

  const handleContinueClick = () => {
    // Check the selected role and set redirectTo accordingly
    switch (selectedRole) {
      case "option1":
        setRedirectTo("/admin");
        break;
      case "option2":
        setRedirectTo("/student");
        break;
      case "option3":
        setRedirectTo("/instructor");
        break;
      case "option4":
        setRedirectTo("/program-coordinator");
        break;
      case "option5":
        setRedirectTo("/qa-organizer");
        break;
      default:
        alert("Please select a role before continuing.");
        break;
    }
  };

  // Conditional rendering based on redirectTo
  if (redirectTo) {
    // Redirect to the selected role's page
    window.location.href = redirectTo;
    return null; // Return null to avoid rendering the component further
  }
  return (
    <div>
      <html lang="en">
        <head>
          <meta charset="UTF-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <meta http-equiv="X-UA-Compatible" content="ie=edge" />
          <link rel="stylesheet" href="../css/signup.css" />
          <title>Signup Form</title>
        </head>
        <body className="signbody">
          <header>
            <Link to="/home" data-after="About">
              <img className="signuplogo" src={avatar} alt="skillbridge" />
            </Link>
          </header>
          <div className="signlogin-wrapper">
            <form action="" className="signform">
              <h3>Create your SkillBridge Account</h3>
              <div className="signinput-group">
                <input type="text" name="loginUser" id="loginUser" required />
                <label for="loginUser">First Name</label>
              </div>
              <div className="signinput-group">
                <input type="text" name="loginUser" id="loginUser" required />
                <label for="loginUser">Last Name</label>
              </div>
              <div className="signinput-group">
                <input type="text" name="loginUser" id="loginUser" required />
                <label for="loginUser">Email</label>
              </div>
              <div className="signinput-group">
                <input
                  type="password"
                  name="loginPassword"
                  id="loginPassword"
                  required
                />
                <label for="loginPassword">Password</label>
              </div>
              <div className="signselect-container">
                <select onChange={handleRoleChange}>
                  <option value="">Select Your Role</option>
                  <option value="option1">Admin</option>
                  <option value="option2">Student</option>
                  <option value="option3">Instructor</option>
                  <option value="option4">Program Coordinator</option>
                  <option value="option5">QA Officer</option>
                </select>
                <div className="signselect-arrow"></div>
              </div>
              <input
                type="submit"
                value="Continue"
                className="signsubmit-btn"
                onClick={handleContinueClick}
              />
              {/* <a href="login.html" className="signforgot-pw">Sign in instead</a> */}
              <Link to="/login" className="signforgot-pw">
                Sign in instead
              </Link>
            </form>
          </div>
        </body>
      </html>
    </div>
  );
}

export default Signup;
