import React from "react";
import "../css/signup.css";
import avatar from "../assets/logo.png";
import { Link } from "react-router-dom";
function EditProfile() {
  return (
    <div className="signbody">
      <header>
        <Link to="/home" data-after="About">
          <img className="signuplogo" src={avatar} alt="skillbridge" />
        </Link>
      </header>
      <div className="signlogin-wrapper">
        <form action="" className="signform">
          <h3>Edit your SkillBridge Account</h3>
          <div className="signinput-group">
            <input
              type="text"
              name="loginUser"
              id="loginUser"
              value="Alex"
              required
            />
          </div>
          <div className="signinput-group">
            <input
              type="text"
              name="loginUser"
              id="loginUser"
              value="Adam"
              required
            />
          </div>
          <div className="signinput-group">
            <input
              type="text"
              name="loginUser"
              id="loginUser"
              value="alexAdam@mavs.uta.edu"
              required
            />
          </div>
          <div className="signinput-group">
            <input
              type="text"
              name="loginUser"
              id="loginUser"
              value="1008782832"
              required
            />
          </div>
          <div className="signselect-container">
            <select>
              <option value="">Select Your Role</option>
              <option value="option1">Student</option>
              <option value="option2">Instructor</option>
              <option value="option3">Program Coordinator</option>
              <option value="option3">QA Officer</option>
            </select>
            <div className="signselect-arrow"></div>
          </div>
          <input type="submit" value="Update" className="signsubmit-btn" />
        </form>
      </div>
    </div>
  );
}

export default EditProfile;
