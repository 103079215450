import React from "react";
import "../css/style.css";
import avatar1 from "../assets/avatar4.jpeg";
import { Link } from "react-router-dom";
function Student() {
  return (
    <div className="stylecontainer">
      <div className="stylesidebar">
        <link
          rel="stylesheet"
          href="https://use.fontawesome.com/releases/v5.15.4/css/all.css"
          integrity="sha384-DyZ88mC6Up2uqS4h/KRgHuoeGwBcD4Ng9SiP4dIRy0EXTlnuz47vAwmeGwVChigm"
          crossorigin="anonymous"
        />
        <ul>
          <li>
            <Link to="/home">
              <i className="fas fa-book"></i>
              <div className="styletitle">SkillBridge</div>
            </Link>
          </li>
          <li>
            <a href="#">
              <i className="fas fa-th-large"></i>
              <div className="styletitle">Student Dashboard</div>
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fas fa-tag"></i>
              <div className="styletitle">Manage Profiles</div>
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fas fa-tag"></i>
              <div className="styletitle">Trobleshoot Issues</div>
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fas fa-tag"></i>
              <div className="styletitle">Grant Permissions</div>
            </a>
          </li>
          <li>
            <Link to="/edit">
              <i className="fas fa-user"></i>
              <div className="styletitle">Edit Profile</div>
            </Link>
          </li>
          <li>
            <Link to="/login">
              <i className="fas fa-user"></i>
              <div className="styletitle">Logout</div>
            </Link>
          </li>
        </ul>
        <div className="stylechatBody">
          <div className="stylechat">
            <div className="styleheader">
              <h3>
                <i className="fas fa-comments"></i> WebChat
              </h3>
            </div>
            <div className="StyleChatbody1">
              <p className="stylemessage">Hello</p>
              <p className="stylemessage user_message">Hi</p>
            </div>
            <div className="stylefooter">
              <form>
                <input type="text" name="" />
                <button>Send</button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="stylemain">
        <div className="styletop-bar">
          <div className="stylesearch">
            <input type="text" name="search" placeholder="Search here.." />
            <label htmlFor="search">
              <i className="fas fa-search"></i>
            </label>
          </div>
          <i className="fas fa-bell"></i>
          <div className="styleuser">
            <img src={avatar1} alt="skillbridge" />
          </div>
        </div>
        <div className="stylecards">
          <div className="stylecard">
            <div className="stylecard-content">
              <div className="stylenumber">5</div>
              <div className="stylecard-name">Roles</div>
            </div>
            <div className="styleicon-box">
              <i className="fas fa-tag"></i>
            </div>
          </div>
          <div className="stylecard">
            <div className="stylecard-content">
              <div className="stylenumber">67</div>
              <div className="stylecard-name">Courses</div>
            </div>
            <div className="styleicon-box">
              <i className="fas fa-tag"></i>
            </div>
          </div>
          <div className="stylecard">
            <div className="stylecard-content">
              <div className="stylenumber">5</div>
              <div className="stylecard-name">Exams</div>
            </div>
            <div className="styleicon-box">
              <i className="fas fa-tag"></i>
            </div>
          </div>
          <div className="stylecard">
            <div className="stylecard-content">
              <div className="stylenumber">7</div>
              <div className="stylecard-name">Assignments</div>
            </div>
            <div className="styleicon-box">
              <i className="fas fa-tag"></i>
            </div>
          </div>
        </div>
        <div className="styletables">
          <div className="StyleAllRoles">
            <div className="styleheading">
              <h2>Roles</h2>
              <a href="#" className="stylebtn">
                View All
              </a>
            </div>
            <table className="styleroles">
              <thead>
                <tr>
                  <td>Name</td>
                  <td>Contact</td>
                  <td>Email</td>
                  <td>Role</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Liam Smith Doe</td>
                  <td>+1 999 878 8787</td>
                  <td>liamsmith@uta.edu</td>
                  <td>Administrator</td>
                </tr>
                <tr>
                  <td>Matt Smith</td>
                  <td>+1 999 878 8787</td>
                  <td>mattsmith@uta.edu</td>
                  <td>Instructor</td>
                </tr>
                <tr>
                  <td>William Smith</td>
                  <td>+1 999 878 8787</td>
                  <td>williamsmith@uta.edu</td>
                  <td>Quality Assurance</td>
                </tr>
                <tr>
                  <td>Luke Smith</td>
                  <td>+1 999 878 8787</td>
                  <td>lukesmith@uta.edu</td>
                  <td>Program Coordinator</td>
                </tr>
                <tr>
                  <td>Luke Smith</td>
                  <td>+1 999 878 8787</td>
                  <td>lukesmith@uta.edu</td>
                  <td>Program Coordinator</td>
                </tr>
                <tr>
                  <td>Luke Smith</td>
                  <td>+1 999 878 8787</td>
                  <td>lukesmith@uta.edu</td>
                  <td>Program Coordinator</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="styleAllcourses">
            <div className="styleheading">
              <h2>Courses</h2>
              <a href="#" className="stylebtn">
                View All
              </a>
            </div>
            <table className="courses">
              <thead>
                <tr>
                  <td>Number</td>
                  <td>Name</td>
                  <td>Department</td>
                  <td>Instructor</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>CSE 5345</td>
                  <td>Web Development</td>
                  <td>CSE</td>
                  <td>Elizabeth Diaz</td>
                </tr>
                <tr>
                  <td>CSE 5345</td>
                  <td>Web Developement</td>
                  <td>CSE</td>
                  <td>Elizebeth Diaz</td>
                </tr>
                <tr>
                  <td>CSE 5345</td>
                  <td>Web Developement</td>
                  <td>CSE</td>
                  <td>Elizebeth Diaz</td>
                </tr>
                <tr>
                  <td>CSE 5345</td>
                  <td>Web Developement</td>
                  <td>CSE</td>
                  <td>Elizebeth Diaz</td>
                </tr>
                <tr>
                  <td>CSE 5345</td>
                  <td>Web Developement</td>
                  <td>CSE</td>
                  <td>Elizebeth Diaz</td>
                </tr>
                <tr>
                  <td>CSE 5345</td>
                  <td>Web Developement</td>
                  <td>CSE</td>
                  <td>Elizebeth Diaz</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="styleAllcourses">
            <div className="styleheading">
              <h2>Exams</h2>
              <a href="#" className="stylebtn">
                View All
              </a>
            </div>
            <table className="courses">
              <thead>
                <tr>
                  <td>Course</td>
                  <td>Exam Type</td>
                  <td>Date</td>
                  <td>Instructor</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Distributed Systems</td>
                  <td>Quiz 1</td>
                  <td>10-10-2023</td>
                  <td>Chance Ray</td>
                </tr>
                <tr>
                  <td>Big Data</td>
                  <td>Quiz 1</td>
                  <td>10-10-2023</td>
                  <td>Jhon Adam</td>
                </tr>
                <tr>
                  <td>Distributed Systems</td>
                  <td>Quiz 1</td>
                  <td>10-10-2023</td>
                  <td>Chance Ray</td>
                </tr>
                <tr>
                  <td>Distributed Systems</td>
                  <td>Quiz 1</td>
                  <td>10-10-2023</td>
                  <td>Chance Ray</td>
                </tr>
                <tr>
                  <td>Distributed Systems</td>
                  <td>Quiz 1</td>
                  <td>10-10-2023</td>
                  <td>Chance Ray</td>
                </tr>
                <tr>
                  <td>WDM</td>
                  <td>Quiz 1</td>
                  <td>10-10-2023</td>
                  <td>Elizibeth Diaz</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Student;
