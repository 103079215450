import "./App.css";
import Home from "./Components/Home";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Signup from "./Components/SignUp";
import Login from "./Components/Login";
import Admin from "./Components/admin";
import Instructor from "./Components/instructor";
import QAOrganizer from "./Components/QAOrganizer";
import ProgramCoordinator from "./Components/pc";
import EditProfile from "./Components/EditProfile";
import Student from "./Components/Student";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="home" element={<Home />} />
          <Route path="signup" element={<Signup />} />
          <Route path="login" element={<Login />} />
          <Route path="admin" element={<Admin />} />
          <Route path="instructor" element={<Instructor />} />
          <Route path="qa-organizer" element={<QAOrganizer />} />
          <Route path="program-coordinator" element={<ProgramCoordinator />} />
          <Route path="edit" element={<EditProfile />} />
          <Route path="student" element={<Student />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
