import React from "react";
import "../css/login.css";
import "../App.css";
import { Link } from "react-router-dom";
import avatar from "../assets/logo.png";
import avatar1 from "../assets/avatar.png";
function Login() {
  const handleFormSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission

    // Redirect to the Admin page
    // You can replace '/admin' with the actual route for the Admin page
    window.location.href = "/admin";
  };
  return (
    <div className="loginclass">
      <header>
        <Link to="/home" data-after="About">
          <img className="loginlogo" src={avatar} alt="skillbridge" />
        </Link>
      </header>
      <div className="login-wrapper">
        <form onSubmit={handleFormSubmit} className="loginform">
          <img src={avatar1} alt="skillbridge" />
          <h2>Login</h2>
          <div className="logininput-group">
            <input type="text" name="loginUser" id="loginUser" required />
            <label htmlFor="loginUser">Email</label>
          </div>
          <div className="logininput-group">
            <input
              type="password"
              name="loginPassword"
              id="loginPassword"
              required
            />
            <label htmlFor="loginPassword">Password</label>
          </div>
          <input type="submit" value="Login" className="loginsubmit-btn" />
          <a href="#forgot-pw" class="forgot-pw">
            Need help?
          </a>
          <p className="login-para" style={{ fontSize: "1.5rem" }}>
            New to SkillBridge?<span> </span>
            <Link to="/signup" className="login-link">
              Sign up now
            </Link>
          </p>
        </form>
        <div id="forgot-pw">
          <form action="" className="loginform">
            <a href="#" className="loginclose">
              &times;
            </a>
            <h2>Reset Password</h2>
            <div className="logininput-group">
              <input type="email" name="email" id="email" required />
              <label htmlFor="email">Email</label>
            </div>
            <input type="submit" value="Submit" className="loginsubmit-btn" />
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
