import React from "react";
import "../css/pc.css";
import { Link } from "react-router-dom";
import avatar1 from "../assets/avatar4.jpeg";
function ProgramCoordinator() {
  return (
    <div className="pccontainer">
      <div className="pcsidebar">
        <link
          rel="stylesheet"
          href="https://use.fontawesome.com/releases/v5.15.4/css/all.css"
          integrity="sha384-DyZ88mC6Up2uqS4h/KRgHuoeGwBcD4Ng9SiP4dIRy0EXTlnuz47vAwmeGwVChigm"
          crossOrigin="anonymous"
        />
        <ul>
          <li>
            <Link to="/home">
              <i className="fas fa-book"></i>
              <div className="pctitle">SkillBridge</div>
            </Link>
          </li>
          <li>
            <a href="#">
              <i className="fas fa-th-large"></i>
              <div className="pctitle">PC Dashboard</div>
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fas fa-tag"></i>
              <div className="pctitle">Manage Programs</div>
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fas fa-tag"></i>
              <div className="pctitle">Track Student Progress</div>
            </a>
          </li>
          <li>
            <Link to="/edit">
              <i className="fas fa-user"></i>
              <div className="pctitle">Edit Profile</div>
            </Link>
          </li>
          <li>
            <Link to="/login">
              <i className="fas fa-user"></i>
              <div className="pctitle">Logout</div>
            </Link>
          </li>
        </ul>
        <div className="pcchatBody">
          <div className="pcchat">
            <div className="pcheader">
              <h3>
                <i className="fas fa-comments"></i> WebChat
              </h3>
            </div>
            <div className="PcChatbody1">
              <p className="pcmessage">Hello</p>
              <p className="pcmessage user_message">Hi</p>
            </div>
            <div className="pcfooter">
              <form>
                <input type="text" name="" />
                <button>Send</button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="pcmain">
        <div className="pctop-bar">
          <div className="pcsearch">
            <input type="text" name="search" placeholder="Search here.." />
            <label htmlFor="search">
              <i className="fas fa-search"></i>
            </label>
          </div>
          <i className="fas fa-bell"></i>
          <div className="pcuser">
            <img src={avatar1} alt="skillbridge" />
          </div>
        </div>
        <div className="pccards">
          <div className="pccard">
            <div className="pccard-content">
              <div className="pcnumber">25</div>
              <div className="pccard-name">Total Programs 2023</div>
            </div>
            <div className="pcicon-box">
              <i className="fas fa-tag"></i>
            </div>
          </div>
          <div className="pccard">
            <div className="pccard-content">
              <div className="pcnumber">23</div>
              <div className="pccard-name">Student Programs</div>
            </div>
            <div className="pcicon-box">
              <i className="fas fa-tag"></i>
            </div>
          </div>
          <div className="pccard">
            <div className="pccard-content">
              <div className="number">5</div>
              <div className="pccard-name">Instructor Programs</div>
            </div>
            <div className="pcicon-box">
              <i className="fas fa-tag"></i>
            </div>
          </div>
          <div className="pccard">
            <div className="pccard-content">
              <div className="pcnumber">17</div>
              <div className="pccard-name">QAO Programs</div>
            </div>
            <div className="pcicon-box">
              <i className="fas fa-tag"></i>
            </div>
          </div>
        </div>
        <div className="pctables">
          <div className="pcAllRoles">
            <div className="pcheading">
              <h2>Manage Programs</h2>
              <a href="#" className="pcbtn">
                View All
              </a>
            </div>
            <table className="pcroles">
              <thead>
                <tr>
                  <th>Program Name</th>
                  <th>Candidate Name</th>
                  <th>Role</th>
                  <th>UTA Email</th>
                  <th>Program Description</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Cultural Fest</td>
                  <td>Alex Adam</td>
                  <td>Student</td>
                  <td>alexAdam@mavs.uta.edu</td>
                  <td>How to plan a Cultural Fest?</td>
                  <td>
                    <i className="fas fa-eye"></i>&nbsp;
                    <i className="fas fa-edit"></i>&nbsp;
                    <i className="fas fa-trash"></i>&nbsp;
                  </td>
                </tr>
                <tr>
                  <td>Program 2</td>
                  <td>Alex2 Adam</td>
                  <td>Instructor</td>
                  <td>alex2Adam@mavs.uta.edu</td>
                  <td>How to plan a Cultural Fest?</td>
                  <td>
                    <i className="fas fa-eye"></i>&nbsp;
                    <i className="fas fa-edit"></i>&nbsp;
                    <i className="fas fa-trash"></i>&nbsp;
                  </td>
                </tr>
                <tr>
                  <td>Program 3</td>
                  <td>Alex3 Adam</td>
                  <td>Admin</td>
                  <td>alex3Adam@mavs.uta.edu</td>
                  <td>How to plan a Cultural Fest?</td>
                  <td>
                    <i className="fas fa-eye"></i>&nbsp;
                    <i className="fas fa-edit"></i>&nbsp;
                    <i className="fas fa-trash"></i>&nbsp;
                  </td>
                </tr>
                <tr>
                  <td>Program 4</td>
                  <td>Alex4 Adam</td>
                  <td>QAO</td>
                  <td>alex4Adam@mavs.uta.edu</td>
                  <td>How to plan a Cultural Fest?</td>
                  <td>
                    <i className="fas fa-eye"></i>&nbsp;
                    <i className="fas fa-edit"></i>&nbsp;
                    <i className="fas fa-trash"></i>&nbsp;
                  </td>
                </tr>
                <tr>
                  <td>Program 4</td>
                  <td>Alex4 Adam</td>
                  <td>QAO</td>
                  <td>alex4Adam@mavs.uta.edu</td>
                  <td>How to plan a Cultural Fest?</td>
                  <td>
                    <i className="fas fa-eye"></i>&nbsp;
                    <i className="fas fa-edit"></i>&nbsp;
                    <i className="fas fa-trash"></i>&nbsp;
                  </td>
                </tr>
                <tr>
                  <td>Program 4</td>
                  <td>Alex4 Adam</td>
                  <td>QAO</td>
                  <td>alex4Adam@mavs.uta.edu</td>
                  <td>How to plan a Cultural Fest?</td>
                  <td>
                    <i className="fas fa-eye"></i>&nbsp;
                    <i className="fas fa-edit"></i>&nbsp;
                    <i className="fas fa-trash"></i>&nbsp;
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="pcAllcourses">
            <div className="pcheading">
              <h2>Track Student Progress</h2>
              <a href="#" className="pcbtn">
                View All
              </a>
            </div>
            <table className="pccourses">
              <thead>
                <tr>
                  <th>Student Name</th>
                  <th>UTA Email</th>
                  <th>UTA ID</th>
                  <th>Average Grade</th>
                  <th>Probation Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Student 1</td>
                  <td>student1@mavs.uta.edu</td>
                  <td>100943653</td>
                  <td>C</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>Student 2</td>
                  <td>student2@mavs.uta.edu</td>
                  <td>100943654</td>
                  <td>B</td>
                  <td>No</td>
                </tr>
                <tr>
                  <td>Student 3</td>
                  <td>student3@mavs.uta.edu</td>
                  <td>100943655</td>
                  <td>A</td>
                  <td>No</td>
                </tr>
                <tr>
                  <td>Student 4</td>
                  <td>student4@mavs.uta.edu</td>
                  <td>100943656</td>
                  <td>C</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>Student 5</td>
                  <td>student5@mavs.uta.edu</td>
                  <td>100943657</td>
                  <td>B</td>
                  <td>No</td>
                </tr>
                <tr>
                  <td>Student 6</td>
                  <td>student6@mavs.uta.edu</td>
                  <td>100943658</td>
                  <td>A</td>
                  <td>No</td>
                </tr>
                <tr>
                  <td>Student 7</td>
                  <td>student7@mavs.uta.edu</td>
                  <td>100943659</td>
                  <td>C</td>
                  <td>Yes</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="pcfeedback">
          <div className="pcfeedback1">
            <h1>Contact Students</h1>
            <form>
              <input type="text" placeholder="Subject" />
              <input type="email" placeholder="Email ID...." />
              <textarea
                style={{ fontSize: "16px" }}
                placeholder="Email Body..."
              ></textarea>
              <button type="submit">Send Recommendation</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProgramCoordinator;
